<template>
  <div class="cntContatti">
    <div style="width: 90vw">
      <div class="divLoading" v-if="loadingMap">
        <v-progress-circular :size="30" :width="4" color="#283b4d" indeterminate></v-progress-circular>
      </div>
      <iframe
        src="https://www.google.com/maps/embed?pb=!1m17!1m12!1m3!1d306.58398573728726!2d12.43266356687039!3d43.02596772305717!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m2!1m1!2zNDPCsDAxJzMzLjkiTiAxMsKwMjUnNTcuNCJF!5e0!3m2!1sit!2sit!4v1701849857343!5m2!1sit!2sit"
        width="100%"
        height="500"
        style="border:0;"
        allowfullscreen=""
        loading="lazy"
        referrerpolicy="no-referrer-when-downgrade"
        @load="finishMapLoading"
      ></iframe>
    </div>
    <div class="numeri">
      <div class="contact" v-for="(contatto, index) in contatti" :key="index">
        <v-icon color="white" :large="!isPhone">{{ contatto.icon }}</v-icon>
        <span class="infoContatto" v-if="contatto.type == 'text'">{{ contatto.info }}</span>
        <a class="infoContatto" :href="'tel://' + contatto.info" v-if="contatto.type == 'phone'">{{ contatto.info }}</a>
        <a class="infoContatto" :href="'mailto://' + contatto.info" v-if="contatto.type == 'mail'">{{ contatto.info }}</a>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
export default {
  props: ["isPhone"],
  head: {
    title: {
      inner: "Contatti"
    }
  },

  created() {
    this.loadingMap = true;
  },

  data() {
    return {
      loadingMap: false
    };
  },

  computed: {
    ...mapState({
      contatti: state => state.contatti
    })
  },

  methods: {
    finishMapLoading() {
      this.loadingMap = false
    }
  }
};
</script>

<style lang="scss">
.cntContatti {
  margin: 100px;
  display: flex;
  flex-direction: column;
  align-items: center;
  .divLoading {
    width: 100%;
    height: 500px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: white;
  }
  .numeri {
    margin-top: 20px;
    align-self: flex-start;
    .contact {
      display: flex;
      align-items: center;
      font-size: 2rem;
      .infoContatto {
        margin-left: 10px;
      }
    }
  }
}

.phone {
  .cntContatti {
    margin: 80px 20px 20px 20px;
    .numeri {
      .contact {
        font-size: 1.2rem;
      }
    }
  }
}
</style>
